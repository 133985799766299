<template>
    
    <div class="education-list-container" >
        <div>
            <span><h3>Current Course Offerings</h3></span>
            <template v-for="course in courses" :key="course.courseId">
                <div class="card" >

                    <div v-if="isMobile" class="flex-column">
                        <div class="flex-row">
                            <UserAvatar :imageSrc="(course.iconUrl ? course.iconUrl: '/assets/images/eduAccount.png')" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 16px;">
                                <span style="font-size: 20px;">
                                    {{ course?.name }}
                                </span>
                                
                                <div>
                                    <span>{{course?.description}}</span>
                                   
                                </div>
                               
                                <Button @click='joinCourse(course)' class="green-button__secondary action-button">
                                    <span>Join</span>
                                </Button>
                               
                            </div>
                            <img v-if="course.product_id" class="course-list-icon" src="assets/images/paid-course.png"/>
                           
                        </div>
                       
                    </div>

                    <div v-else class="flex-row">
                        <div class="flex-row" style="width: 80%;">
                            <UserAvatar :imageSrc="(course.iconUrl ? course.iconUrl: '/assets/images/eduAccount.png')" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
                                <span style="font-size: 20px;">
                                    {{ course?.name }}
                                </span>
                                <div>
                                    <span>{{course?.description}}</span>
                                   
                                </div>
                                
                                
                                <Button label="Join" @click="joinCourse(course)" class="green-button__secondary action-button" >
                                    
                                </Button>
                                
                            </div>
                        </div>
                        <div class="data-container" style="width: 60%;">
                            

                        </div>
                        <img v-if="course.productId " class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'This course has a fee.'"/>
                        <img v-else-if="course.subscriptionRequired" class="course-list-icon" src="assets/images/paid-course.png"  v-tooltip.top="'A group subscription is required to access this course.'"/>
                       
                        <img v-else class="course-list-icon" src="assets/images/free.png" v-tooltip.top="'This course is offered for free.'"/>
                    </div>

                </div>
            </template>
            
        </div>
        
    </div>

    
        
</template>

<script>
// import Column from 'primevue/column';
// import DataTable from 'primevue/datatable';
import UserAvatar from "../profile/UserAvatar.vue";

// import router from '../../../router';

import BrowserUtils from "../../utilities/BrowserUtils";


// import UserService from '../../service/UserService';
// import EventBus from '../../event-bus';
// import { isAfter, isBefore, startOfDay, add } from 'date-fns';


export default {
    name: 'GroupLandingPageCourseList',
    emits: [ 'join-course'],
    props: {
       
        courses: {
            type: Array,
            required: false
        },
        

    },
    components: {
        UserAvatar,
      
        
    },
    data() {
        return {
            
            
        }
    },
   

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        
    },

   
    
   
    methods: {


      
        joinCourse( course) {//eslint-disable-line
           this.$emit('join-course', course);
        },

        

       
    }
}
</script>

<style scoped>
.competition-list-icon {
    max-width: 5em;
    max-height: 5em;
}

.course-list-icon {
    max-width: 2em;
    max-height: 2em;
}
.competitions-list-container {
    display: flex;
    margin: 1rem 1rem 60px 1rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

.hosted-by-label {
    color: #33CC99;
}

.hosted-by-label:hover {
    cursor: pointer;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.data-container * {
    min-width: 222px;
}

.action-button {
    padding: 8px 16px;
    border-style: solid;
    border-radius: 28px;
    text-align: center;
    width: 200px;
}

.action-button span {
    width: 100%;
}

@media (max-width: 760px) {
    .competitions-list-container {
        margin-left: 1rem;
    }
}
</style>