<template>
    <div class="card" id="landingPageContainer">
        <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
            <div class="flex-row"  >
                <span><h3>Group Landing Page</h3></span>
               <div class="flex-row" style="margin-left:auto;margin-top:5px;">      
                        <span><b>Preview</b></span>
                        <div class="info-icon-container" >
                            <i class="pi pi-info-circle" 
                                v-tooltip.bottom="`Turn on to preview what your landing page will look like.`"></i>
                        </div>
                
                        <InputSwitch v-model="preview" class="group-actions-switch" style="margin-left:5px;"/>
                    
                </div>
                <div class="flex-row" :style="(!preview ? 'margin-left:auto;opacity:0.3;': 'margin-left:auto;')" >      
                        
                       
                        <SelectButton v-model="previewMode" :options="previewOptions" aria-labelledby="basic" :disabled="!preview" v-tooltip.bottom="`Toggle between Desktop and Mobile preview`"/>
                        <!-- <InputSwitch v-model="desktopMode" class="group-actions-switch" style="margin-left:5px; " :disabled="!preview"/> -->
                    
                </div>
            </div>
             <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if="!loading && !preview">
                 <vue-editor id="landingPageEditor" ref="landingPageEditor" v-model="landingPage.content" :editorOptions="createGroupLandingPageEditorSettings('#landingPageContainer')"  
                                :customModules="editorModules" 
                               
                                />
                <div style="max-width: 400px;">
                    <div class="flex-row" style="margin-top:5px;">
                        
                            <span>Include Course Listing:</span>
                        
                            <div class="info-icon-container" >
                                <i class="pi pi-info-circle" 
                                    v-tooltip.bottom="`When enabled a listing of any courses currently open for admission are listed along with a Join button.`"></i>
                            </div>
                    
                            <InputSwitch v-model="landingPage.includeCourses" class="group-actions-switch" style="margin-left:auto;"/>
                       
                    </div>
                    <div class="flex-row" style="margin-top:5px;">
                       
                            <span>Go Live:</span>
                            <div class="info-icon-container" >
                                <i class="pi pi-info-circle" 
                                    v-tooltip.bottom="`When enabled this landing page is displayed to all non-members when they view the group profile.`"></i>
                            </div>
                    
                            <InputSwitch v-model="landingPage.live" class="group-actions-switch" style="margin-left:auto;"/>
                        
                    </div>
                </div>
                
                <Button @click='save()' class="green-button__secondary action-button" style="display:block;margin:auto;" :loading="saving" :disabled="!landingPage.content || landingPage.content.trim().length <= 0">
                                        <span>Save</span>
                                    </Button>
            </div>
            
        </div>
        
        
    </div>
    <div v-if="!loading && preview" :class="previewClass">
        <div class="landing-preview" v-html="landingPage.content"></div>
                 
    
        <div v-if="landingPage.includeCourses"  style="margin-top:15px;">
            <GroupLandingPageCourseList  :courses="courses"/>        
        </div>
    </div>
   
  
</template>

<script>
import GroupService from '../../service/GroupService';


import LoadingSpinner from '../common/LoadingSpinner.vue';



import { VueEditor } from "vue3-editor";
import { createGroupLandingPageEditorSettings, groupLandingPageEditorModules, QuillAutoSaveHandler} from "../editor/config.js";
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';

import GroupLandingPageCourseList from './GroupLandingPageCourseList.vue';

export default {
    name: 'GroupLandingePageEditor',
   
    components: { 
        LoadingSpinner,
        VueEditor,
        InputSwitch,
        SelectButton,
        GroupLandingPageCourseList
    },
    props: {
        selectedGroup: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
          createGroupLandingPageEditorSettings,
          
           editorModules: groupLandingPageEditorModules,
           loading: false,
           landingPage: {
            content: null,
            includeCourses: false,
            live: false
           },
           autoSaveHandler: null,
           saving: false,
           preview: false,
           previewOptions: ['Desktop', 'Mobile'],
           previewMode: 'Desktop',
           courses: [],
           
        };
    },

    mounted() {
       console.log("mounted group landing page editor")
       this.loadLandingPage();
        
       

       
    },
    computed: {
      

        previewClass() {
             if(this.previewMode == 'Desktop' ){
                return 'card desktop-preview'
            }
            else {
                return 'card mobile-preview'
            }
        }
    },

    watch: {
        preview(newVal, oldVa) { //eslint-disable-line
            if( newVal){
                GroupService.getOpenGroupCourses(this.selectedGroup.groupId).then(resp => {
                    if( resp.data.status == 'success'){
                        this.courses = resp.data.courses;
                    }
                });
            }
        }
    },

    methods: {
         
        loadLandingPage() {

           
            this.loading = true;

            GroupService.getGroupLandingPage(this.selectedGroup.groupId).then(resp => {
                this.loading = false;
                if (resp.data.status == "success") {

                    this.landingPage.content = resp.data.page.content;
                    this.landingPage.live = resp.data.page.live;
                    this.landingPage.includeCourses = resp.data.page.includeCourses;
                    //this.setupAutoSave();
                    // console.log("getGroupsHostedContests", this.competitions);

                    
                }
            });
           
        },

        setupAutoSave() {
             let getContent = () => {
              return this.landingPage.content;
              
            };
            this.autoSaveHandler = new QuillAutoSaveHandler("groupLandingePages", {getContent: getContent}, {type: 'groupLandingPage', noteId: null, title: null, content: this.landingePage.content});

        },

        save() {
            this.saving = true;
            GroupService.saveGroupLandingPage(this.selectedGroup.groupId, this.landingPage).then(resp => {
                this.saving = false;
                if( resp.data.status == "success"){
                    if( this.landingPage.live){
                        this.$toast.add({ severity: 'success', summary: `Your group landing page was saved and is now live.`, life: 3500, group: "center"});
                    }
                    else {
                          this.$toast.add({ severity: 'success', summary: `Your group landing page was saved.`, life: 3500, group: "center"});
                    }
                    
                }
                else {
                    console.log("error saving landinge page", resp);
                    this.$toast.add({ severity: 'error', summary: `There was an error attempting to save your changes.  Contact support if the problem persists.`, life: 3500, group: "center"});
                    
                }
            }).catch(err => {
                this.saving = false;
                console.log("unexpected error trying to save group landing page.", err)
                this.$toast.add({ severity: 'error', summary: `There was an error attempting to save your changes.  Contact support if the problem persists.`, life: 3500, group: "center"});
                    
            });
        }
    },
};
</script>
<style>
.landing-preview .ql-align-center {
    text-align: center;
}

.landing-preview .ql-align-right {
    text-align: right;
}

.landing-preview .ql-align-justify {
    text-align: justify;
}


.landing-preview ol li:before {
        content: counter(list-0,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-1:before {
        content: counter(list-1,lower-alpha) ". ";
    }

    .landing-preview ol li.ql-indent-2:before {
        content: counter(list-2,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-3:before {
        content: counter(list-3,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-4:before {
        content: counter(list-4,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-5:before {
        content: counter(list-5,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-6:before {
        content: counter(list-6,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-7:before {
        content: counter(list-7,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-8:before {
        content: counter(list-8,lower-roman) ". ";
    }

 .landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.landing-preview li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.landing-preview ul>li:before {
    content: "\2022";
}

.landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

  .landing-preview ol>li, .landing-preview ul>li {
    list-style-type: none;
} 

.landing-preview ol, .landing-preview ul{
    
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    list-style-position: inside;
}

 .landing-preview ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.landing-preview ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
} 

/* .landing-preview ol li.ql-indent-1 {
    counter-increment: list-1;
} */

.landing-preview ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-2 {
    counter-increment: list-2;
}

.landing-preview ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-3 {
    counter-increment: list-3;
}

.landing-preview ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-4 {
    counter-increment: list-4;
}

.landing-preview ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-5 {
    counter-increment: list-5;
}

.landing-preview ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-6 {
    counter-increment: list-6;
}

.landing-preview ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}

.landing-preview ol li.ql-indent-7 {
    counter-increment: list-7;
}

.landing-preview ol li.ql-indent-8 {
    counter-reset: list-9;
}

.landing-preview ol li.ql-indent-8 {
    counter-increment: list-8;
}
</style>

<style scoped>



.desktop-preview {

}

.mobile-preview {
    width: 390px;
    /* height: 844px; */
    align-content: center;
    margin:auto;
}



::v-deep(.landing-preview ol li:not(.ql-direction-rtl), .landing-preview ul li:not(.ql-direction-rtl) ){
    padding-left: 1.5em;
}

::v-deep(.landing-preview li.ql-indent-1:not(.ql-direction-rtl) ){
    padding-left: 4.5em;
}
::v-deep(.landing-preview li.ql-indent-2:not(.ql-direction-rtl) ){
    padding-left: 7.5em;
}
::v-deep(.landing-preview li.ql-indent-3:not(.ql-direction-rtl) ){
    padding-left: 10.5em;
}
::v-deep(.landing-preview li.ql-indent-4:not(.ql-direction-rtl) ){
    padding-left: 13.5em;
}
::v-deep(.landing-preview li.ql-indent-5:not(.ql-direction-rtl) ){
    padding-left: 16.5em;
}
::v-deep(.landing-preview li.ql-indent-6:not(.ql-direction-rtl) ){
    padding-left: 19.5em;
}
::v-deep(.landing-preview li.ql-indent-7:not(.ql-direction-rtl) ){
    padding-left: 22.5em;
}
::v-deep(.landing-preview li.ql-indent-8:not(.ql-direction-rtl) ){
    padding-left: 25.5em;
}

::v-deep(.landing-preview .ql-indent-1:not(.ql-direction-rtl)){
    padding-left: 3em;
}
::v-deep(.landing-preview .ql-indent-2:not(.ql-direction-rtl)){
    padding-left: 6em;
}
::v-deep(.landing-preview .ql-indent-3:not(.ql-direction-rtl)){
    padding-left: 9em;
}
::v-deep(.landing-preview .ql-indent-4:not(.ql-direction-rtl)){
    padding-left: 12em;
}
::v-deep(.landing-preview .ql-indent-5:not(.ql-direction-rtl)){
    padding-left: 15em;
}
::v-deep(.landing-preview .ql-indent-6:not(.ql-direction-rtl)){
    padding-left: 18em;
}
::v-deep(.landing-preview .ql-indent-7:not(.ql-direction-rtl)){
    padding-left: 21em;
}
::v-deep(.landing-preview .ql-indent-8:not(.ql-direction-rtl)){
    padding-left: 24em;
}


.info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}

.confirm-container {
   

    margin: 12px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}
.view-blocked-users-button {
    width: fit-content;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    color: #32364e;
    text-decoration: underline;
}
.view-blocked-users-button:hover {
    color: black;
}

.action-button {
    padding: 6px 16px;
    border-style: solid;
    border-radius: 16px;
    text-align: center;
    width: 80px;
    /* margin-left: 5px; */
}

.action-button span {
    width: 100%;
}
</style>